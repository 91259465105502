import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useLoading } from '../../commonHooks/useLoading';
import { useAppDispatch } from '../../Redux/Store';
import { CommonConstants } from '../../utils/constants/CommonConstants';
import { validateAccordingType } from '../../utils/validation';
import { destinationConstants } from '../../utils/constants/DestinationConstant';
import {
    IDestinationDetail,
    IDestinationDetailsError,
    IDestinationModal,
} from '../../utils/Interface/DestinationInterface';
import {
    createDestination,
    fetchInactiveDestinationInfo,
    updateDestinationDetail,
} from '../../Redux/Actions/destinationActions';
import FullPageLoader from '../common/FullPageLoader';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { ActivateItemModal } from '../../apps/shippingTracker/modules/BuyPo/components/ActivateItemModal';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';
import { StateList } from '../../utils/constants/StateListConstants';
import { DESTINATION_INITIAL_STATE } from '../../Redux/Constants/destinationInitialState';

const DestinationModal = ({
    showModal,
    closeModalWithChanges,
    editDestinationDetails,
    showActiveField,
}: IDestinationModal) => {
    const [destinationDetail, setDestinationDetail] =
        useState<IDestinationDetail>(editDestinationDetails);
    const [destinationDetailsError, setDestinationDetailsError] =
        useState<IDestinationDetailsError>(
            destinationConstants.initialBinDetailsError,
        );
    const [inActiveDestination, setInActiveDestination] =
        useState<IDestinationDetail>();
    const {
        isOpen: isActivateModalOpen,
        open: openActivateModal,
        close: closeActivateModal,
    } = useModalStatus();
    const { loading, setLoading } = useLoading();
    const dispatch = useAppDispatch();

    const validateForm = (destinationErrorCopy: IDestinationDetailsError) => {
        let isFormValid = true;
        const validateFields = ['postalCode', 'coordinates'];
        const validateTypes = ['zipcode', 'coordinates'];

        validateFields.forEach((validateField, index) => {
            const isValid = validateAccordingType(
                validateTypes[index],
                destinationDetail[validateField],
            );
            destinationErrorCopy[validateField] = !isValid;
            if (!isValid) {
                isFormValid = false;
            }
        });

        setDestinationDetailsError(destinationErrorCopy);

        return isFormValid;
    };

    const validateDestinationDetails = (): boolean => {
        let isValid = true;
        const { name } = destinationDetail;
        const updatedDestinationDetailErrors = {
            ...destinationDetailsError,
            name: !name,
        };
        setDestinationDetailsError(updatedDestinationDetailErrors);
        const isFormValid = validateForm(updatedDestinationDetailErrors);
        if (
            Object.values(updatedDestinationDetailErrors).includes(true) ||
            !isFormValid
        ) {
            isValid = false;
        }
        return isValid;
    };

    const handleActivateDestination = async () => {
        const inactiveDestinationInfo = await dispatch(
            fetchInactiveDestinationInfo(destinationDetail.name?.trim()),
        );
        if (inactiveDestinationInfo) {
            setInActiveDestination(inactiveDestinationInfo[0]);
            openActivateModal();
        }
    };

    const handleUpdateDestinationDetail = (): void => {
        const isValid = validateDestinationDetails();
        if (isValid) {
            dispatch(
                updateDestinationDetail(
                    destinationDetail,
                    undefined,
                    closeModalWithChanges,
                    setLoading,
                    handleActivateDestination,
                ),
            );
        }
    };

    const handleCreateDestination = (): void => {
        const isValid = validateDestinationDetails();
        if (isValid) {
            dispatch(
                createDestination(
                    destinationDetail,
                    closeModalWithChanges,
                    setLoading,
                    handleActivateDestination,
                ),
            );
        }
    };

    const handleDetailChange = (
        fieldValue: string | number | boolean,
        fieldName: string,
        type?: string,
    ): void => {
        const isValid = validateAccordingType(type, fieldValue);
        if (isValid) {
            setDestinationDetail(prevDestinationDetail => ({
                ...prevDestinationDetail,
                [fieldName]: fieldValue,
            }));
        }
    };

    const handleCloseActivateModal = (isActivated: boolean) => {
        if (isActivated) {
            if (inActiveDestination) {
                const inActiveDestinationCopy = {
                    ...inActiveDestination,
                    active: true,
                };
                dispatch(
                    updateDestinationDetail(
                        inActiveDestinationCopy,
                        undefined,
                        closeModalWithChanges,
                        setLoading,
                    ),
                );
                closeActivateModal();
            }
        } else {
            closeActivateModal();
        }
    };

    useEffect(() => {
        if (!showModal) {
            setDestinationDetail({
                ...DESTINATION_INITIAL_STATE.createDestinationInitial,
            });
        }
    }, [showModal]);

    return (
        <>
            <ActivateItemModal
                showModal={isActivateModalOpen}
                closeModalHandler={handleCloseActivateModal}
                itemName={destinationDetail.name?.trim()}
                item={destinationConstants.activateModalConstant}
            />
            <Modal
                className="px-2 pt-5 fm-settings-modal"
                size="lg"
                show={showModal}
                centered
                animation
                onHide={() => closeModalWithChanges(false)}
            >
                {loading && <FullPageLoader />}
                <Modal.Header closeButton className="border-bottom-0">
                    <Modal.Title className="text-dark">
                        {editDestinationDetails.name
                            ? `${destinationConstants.editDestination} ${editDestinationDetails?.name}`
                            : destinationConstants.createNewDestinations
                                  .heading}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between">
                        <div className="col-xl-6 col-lg-6 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    destinationConstants.createNewDestinations
                                        .form.name.label
                                }
                                <span className="necessaryFields">*</span>
                            </Form.Label>
                            <Form.Control
                                className={`text-secondary border-${
                                    destinationDetailsError.name ? 'danger' : ''
                                }`}
                                value={destinationDetail?.name}
                                name={
                                    destinationConstants.createNewDestinations
                                        .form.name.name
                                }
                                placeholder={
                                    destinationConstants.createNewDestinations
                                        .form.name.placeholder
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                        'string',
                                    )
                                }
                                maxLength={
                                    destinationConstants.createNewDestinations
                                        .form.name.maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                        {showActiveField && (
                            <div className="custom-checkbox col-5">
                                <input
                                    type="checkbox"
                                    checked={destinationDetail.active}
                                    className="mx-2"
                                    onChange={e =>
                                        handleDetailChange(
                                            !destinationDetail.active,
                                            'active',
                                        )
                                    }
                                />
                                <label className="mx-2">
                                    {FmSettingsConstant.active.label}
                                </label>
                            </div>
                        )}
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="col-xl-6 col-lg-6 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    destinationConstants.createNewDestinations
                                        .form.address1.label
                                }
                            </Form.Label>
                            <Form.Control
                                className="text-secondary"
                                value={destinationDetail?.address1}
                                name={
                                    destinationConstants.createNewDestinations
                                        .form.address1.name
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                        'string',
                                    )
                                }
                                maxLength={
                                    destinationConstants.createNewDestinations
                                        .form.address1.maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                        <div className="col-xl-5 col-lg-5 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    destinationConstants.createNewDestinations
                                        .form.coordinates.label
                                }
                            </Form.Label>
                            <Form.Control
                                className={`text-secondary border-${
                                    destinationDetailsError.coordinates
                                        ? 'danger'
                                        : ''
                                }`}
                                value={destinationDetail?.coordinates}
                                name={
                                    destinationConstants.createNewDestinations
                                        .form.coordinates.name
                                }
                                placeholder={
                                    destinationConstants.createNewDestinations
                                        .form.coordinates.placeholder
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                maxLength={
                                    destinationConstants.createNewDestinations
                                        .form.coordinates.maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="col-xl-6 col-lg-6 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    destinationConstants.createNewDestinations
                                        .form.address2.label
                                }
                            </Form.Label>
                            <Form.Control
                                className="text-secondary"
                                value={destinationDetail?.address2}
                                name={
                                    destinationConstants.createNewDestinations
                                        .form.address2.name
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                        'string',
                                    )
                                }
                                maxLength={
                                    destinationConstants.createNewDestinations
                                        .form.address2.maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                        <div className="col-xl-5 col-lg-5 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    destinationConstants.createNewDestinations
                                        .form.city.label
                                }
                            </Form.Label>
                            <Form.Control
                                className="text-secondary"
                                value={destinationDetail?.city}
                                name={
                                    destinationConstants.createNewDestinations
                                        .form.city.name
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                        'string',
                                    )
                                }
                                maxLength={
                                    destinationConstants.createNewDestinations
                                        .form.city.maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between border-bottom">
                        <div className="col-3 mb-4 start-top pe-0">
                            <Form.Label className="text-secondary">
                                {
                                    destinationConstants.createNewDestinations
                                        .form.state.label
                                }
                            </Form.Label>
                            <Form.Control
                                className="text-secondary"
                                name={
                                    destinationConstants.createNewDestinations
                                        .form.state.name
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                value={destinationDetail.state}
                                as="select"
                                size="sm"
                                type="select"
                            >
                                {StateList.map((state, index) => (
                                    <option key={index} value={state.value}>
                                        {state.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </div>
                        <div className="col-3 mb-4 start-top">
                            <Form.Label className="text-secondary">
                                {
                                    destinationConstants.createNewDestinations
                                        .form.zipCode.label
                                }
                            </Form.Label>
                            <Form.Control
                                className={`text-secondary border-${
                                    destinationDetailsError.postalCode
                                        ? 'danger'
                                        : ''
                                }`}
                                placeholder={
                                    destinationConstants.createNewDestinations
                                        .form.zipCode.placeholder
                                }
                                value={destinationDetail.postalCode}
                                maxLength={
                                    destinationConstants.createNewDestinations
                                        .form.zipCode.maxLength
                                }
                                name={
                                    destinationConstants.createNewDestinations
                                        .form.zipCode.name
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                    )
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                        <div className="col-xl-5 col-lg-5 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    destinationConstants.createNewDestinations
                                        .form.country.label
                                }
                            </Form.Label>
                            <Form.Control
                                value={
                                    destinationDetail.country ||
                                    destinationConstants.createNewDestinations
                                        .form.country.defaultValue
                                }
                                disabled
                                className="text-secondary"
                                size="sm"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 start-top my-4 ">
                        <Form.Label className="text-secondary">
                            {
                                destinationConstants.createNewDestinations.form
                                    .contactName.label
                            }
                        </Form.Label>
                        <Form.Control
                            className="text-secondary"
                            value={destinationDetail?.contactName}
                            name={
                                destinationConstants.createNewDestinations.form
                                    .contactName.name
                            }
                            onChange={e =>
                                handleDetailChange(
                                    e.target.value,
                                    e.target.name,
                                    'string',
                                )
                            }
                            maxLength={
                                destinationConstants.createNewDestinations.form
                                    .contactName.maxLength
                            }
                            size="sm"
                            type="text"
                        />
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="col-xl-6 col-lg-6 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    destinationConstants.createNewDestinations
                                        .form.email.label
                                }
                            </Form.Label>
                            <Form.Control
                                className="text-secondary"
                                value={destinationDetail?.email}
                                name={
                                    destinationConstants.createNewDestinations
                                        .form.email.name
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                        'email',
                                    )
                                }
                                maxLength={
                                    destinationConstants.createNewDestinations
                                        .form.email.maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                        <div className="col-xl-5 col-lg-5 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    destinationConstants.createNewDestinations
                                        .form.altEmail.label
                                }
                            </Form.Label>
                            <Form.Control
                                className="text-secondary"
                                value={destinationDetail?.altEmail}
                                name={
                                    destinationConstants.createNewDestinations
                                        .form.altEmail.name
                                }
                                placeholder={
                                    destinationConstants.createNewDestinations
                                        .form.altEmail.placeholder
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                        'email',
                                    )
                                }
                                maxLength={
                                    destinationConstants.createNewDestinations
                                        .form.altEmail.maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div className="col-xl-6 col-lg-6 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    destinationConstants.createNewDestinations
                                        .form.contactNumber.label
                                }
                            </Form.Label>
                            <Form.Control
                                className="text-secondary"
                                value={destinationDetail?.contactNumber}
                                name={
                                    destinationConstants.createNewDestinations
                                        .form.contactNumber.name
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                        'mobile',
                                    )
                                }
                                maxLength={
                                    destinationConstants.createNewDestinations
                                        .form.contactNumber.maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                        <div className="col-xl-5 col-lg-5 start-top mb-4 ">
                            <Form.Label className="text-secondary">
                                {
                                    destinationConstants.createNewDestinations
                                        .form.altContactNumber.label
                                }
                            </Form.Label>
                            <Form.Control
                                className="text-secondary"
                                value={destinationDetail?.altContactNumber}
                                name={
                                    destinationConstants.createNewDestinations
                                        .form.altContactNumber.name
                                }
                                placeholder={
                                    destinationConstants.createNewDestinations
                                        .form.altContactNumber.placeholder
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                        'mobile',
                                    )
                                }
                                maxLength={
                                    destinationConstants.createNewDestinations
                                        .form.altContactNumber.maxLength
                                }
                                size="sm"
                                type="text"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-top-0">
                    <Button
                        className="secondary"
                        onClick={() => closeModalWithChanges(false)}
                    >
                        {CommonConstants.en.cancel}
                    </Button>
                    <Button
                        onClick={
                            !editDestinationDetails.name
                                ? handleCreateDestination
                                : handleUpdateDestinationDetail
                        }
                    >
                        {CommonConstants.en.save}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DestinationModal;
